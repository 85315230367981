/*
 * 업무구분: 고객
 * 화 면 명: MSPCM001M
 * 화면설명: 고객동의 목록
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.05.20
 * 작 성 자: 이태훈
 * 2차 작성자: sh.park2022
 */
<template>
  <ur-page-container title="동의유형" :show-title="true" type="page" action-type="none" @on-header-left-click="fn_ClickPrev">
    <ur-box-container direction="column" alignV="start" class="ns-agreeArea">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check">
        <mo-checkbox  v-for="item in checkItems" :key="item.label" v-model="checkSelected" :value="item.value" class="full">{{item.label}}</mo-checkbox>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-btn-round-area">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue btn-one" :disabled="!nextBtnDisabled" @click="fn_ClickNext">다음</mo-button>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM001M',
  // 현재 화면 ID
  screenId: 'MSPCM001M',
  // 컴포넌트 선언
  components: {
  },
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      title: '고객동의 목록', // 화면명
      nextBtnDisabled : false, 
      agreeTypeMcnst: false, // 필수컨설팅동의
      agreeTypeMktList: {}, // 마케팅동의 리스트
      infoPrcusCnsntTypCd: '', // 정보활용동의 유형코드(17: 필수컨설팅, 19: 마케팅동의, 18: 둘다)

      // 동의 유형
      checkSelected: [],
      checkItems: [
        {idx: 0, label: '필수컨설팅동의', value: '1'},
        {idx: 1, label: '마케팅동의', value: '2'}
      ],
      mcnstCnsntYn : false, //콜백 필수컨설팅동의여부
      mktCnsntYn: false, //콜백 마케팅동의여부

      pCustNm: '', // 타업무에서 진입시, 넘겨받은 고객명
      preSrnId: '', // 타업무에서 진입시, 넘겨받은 진입한 화면ID
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
  },
  
  /** watch 정의 영역 */
  watch: {
    checkSelected () {
      if(this.checkSelected.length > 0){
        this.nextBtnDisabled = true
      }else{
        this.nextBtnDisabled = false
      }
    }
    
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    let custInfoStore = window.vue.getStore('cmStore').getters.getState.custInfo

    this.pCustNm = !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : ''
    if(!_.isEmpty(this.$route.params)){
      this.infoPrcusCnsntTypCd = this.$route.params.infoPrcusCnsntTypCd

    } else {
      this.pCustNm = !this.$bizUtil.isEmpty(custInfoStore.custNm) ? custInfoStore.custNm : ''
      this.infoPrcusCnsntTypCd = !this.$bizUtil.isEmpty(custInfoStore.infoPrcusCnsntTypCd) ? custInfoStore.infoPrcusCnsntTypCd : ''
    }

    if (this.$bizUtil.isEmpty(this.infoPrcusCnsntTypCd)) {
      custInfoStore.infoPrcusCnsntTypCd = this.infoPrcusCnsntTypCd
      window.vue.getStore('cmStore').dispatch('ADD', custInfoStore)
    }
    
    if(this.infoPrcusCnsntTypCd === '18'){
        this.checkSelected = ['1','2'] //둘다
    }else if(this.infoPrcusCnsntTypCd === '17'){
      this.checkSelected[0] = '1' //필컨만 있는 경우
    }else if(this.infoPrcusCnsntTypCd === '19'){
      this.checkSelected[0] = '2' //마동만 있는 경우
    }

    //다음버튼 활성화 구분
    if(this.checkSelected.length > 0){
      this.nextBtnDisabled = true
    }else{
      this.nextBtnDisabled = false
    }
    
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickPrev)
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    this.$bizUtil.insSrnLog('MSPCM001M')

    // style Class 추가
    let spanList = document.getElementById('scroll_MSPCM001M').getElementsByTagName('span')

    for(let i=0; i<spanList.length; i++) {
      spanList[i].classList.add('full')
    }
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickPrev)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_ClickNext
    * 설명       : 다음 버튼 클릭 시 호출 - (필컨 화면 or 마컨 화면 둘다 이동)
    ******************************************************************************/
    fn_ClickNext () {
      
      if(this.checkSelected.length > 0){
        if(this.checkSelected.length === 2){
          //둘다
            this.infoPrcusCnsntTypCd = '18'
            this.fn_MoveMspcm002mPage()
          }else if(this.checkSelected[0] === '1' && this.checkSelected.length === 1){
            //필컨만 있는 경우
            this.infoPrcusCnsntTypCd = '17'
            this.fn_MoveMspcm002mPage()
          }else{
            //마동만 있는 경우
            this.infoPrcusCnsntTypCd = '19'
            this.fn_MoveMspcm003mPage ()
          }
          
      }
      else{
        let lv_Msg = '필수컨설팅동의 또는 마케팅동의 선택이 필요합니다.'
        this.getStore('confirm').dispatch('ADD', lv_Msg)
      }
    },
    /******************************************************************************
    * Function명 : fn_MoveMspcm002mPage
    * 설명       : MSPCM002M화면으로 이동 (필수컨설팅동의)
    ******************************************************************************/
    fn_MoveMspcm002mPage () {
      // 동의유형코드 Store에 저장
      let custInfoStore = window.vue.getStore('cmStore').getters.getState.custInfo
      custInfoStore.infoPrcusCnsntTypCd = this.infoPrcusCnsntTypCd
      window.vue.getStore('cmStore').dispatch('ADD', custInfoStore)

      // Store 페이지 카운트
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)

      // 현재 화면을 history 배열에 저장
      this.$commonUtil.setLastScreen(this.$options.screenId)
      this.$router.push(
        {
          name: 'MSPCM002M',
          params: {
            name: this.$options.screenId,
            infoPrcusCnsntTypCd: this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨, 마동 둘다 : 18
            custNm: this.pCustNm
          }
        }
      )
    },
    /******************************************************************************
    * Function명 : fn_MoveMspcm003mPage
    * 설명       : MSPCM003M화면으로 이동 (마케팅동의)
    ******************************************************************************/
    fn_MoveMspcm003mPage () {
      // 동의유형코드 Store에 저장
      let custInfoStore = window.vue.getStore('cmStore').getters.getState.custInfo
      custInfoStore.infoPrcusCnsntTypCd = this.infoPrcusCnsntTypCd
      window.vue.getStore('cmStore').dispatch('ADD', custInfoStore)

      // Store 페이지 카운트
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)
      
      // 현재 화면을 history 배열에 저장
      this.$commonUtil.setLastScreen(this.$options.screenId)
      this.$router.push(
        {
          name: 'MSPCM003M',
          params: {
            name: this.$options.screenId,
            infoPrcusCnsntTypCd: this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨, 마동 둘다 : 18
            custNm: this.pCustNm
          }
        }
      )
    },
    /******************************************************************************
      * Function명 : fn_ClickPrev
      * 설명       : 이전 버튼 클릭 시 호출 - (고객동의목록 화면 이동)
      ******************************************************************************/
      fn_ClickPrev () {
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)
        this.$router.go(-1)
      },
      /******************************************************************************
      * Function명: _Back
      * 설명: 헤더 영역 Back Button 파라미터 넘기기
      *         => 사용안함
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      _Back () {
        return
        let alertMsg = '고객동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 고객정보는 저장되지 않으며, 고객동의는 완료되지 않습니다.'
        let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
        let preSrnId = !this.$bizUtil.isEmpty(preSrnObj.srnId) ? preSrnObj.srnId : 'MSPBC002M'
        
        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            content: alertMsg,
            title_pos_btn: '나가기'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
              
              window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화
              this.$router.push({ name: preSrnId, params: preSrnObj.rtnParam })
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })

      },
  }
}
</script>
